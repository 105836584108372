import React from 'react'
import CSoon from '../../component/CSoon'

function UDashboard() {
  return (
    <div className="UDashboardContainer">
      <CSoon/>
    </div>
  )
}

export default UDashboard