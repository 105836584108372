import React from 'react'
import CSoon from '../../component/CSoon'

function USetting() {
  return (
    <div className='USettingContainer'>
      <CSoon/>
    </div>
  )
}

export default USetting